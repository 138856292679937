<template>
    <KeywordDestinationSelector
        :destinations="destinations"
        v-model:entities="entities"
        :existing-shared-sets="existingSharedSetNames"
        :error="onPushError"
    >
        <template #custom-entity-negative-list="{ selector }">
            <KeywordDestinationEntity v-model:entity="accountLevelEntity" color="red" />
        </template>
        <template #empty-state-negative-list="{ selector }">
            <KeywordDestinationEmptyState v-if="!performanceMaxActive">
                <template #text>
                    Opteo could not find any shared negative lists in your account. To create one,
                    click the button below. Otherwise, you can add this negative to a campaign (or
                    ad group) by switching to the
                    <b>Campaigns</b> tab.
                </template>
                <template #button>
                    <oButton color="white" @clicked="negativeListModalOpen = true">
                        Create Negative List
                    </oButton>
                </template>
            </KeywordDestinationEmptyState>
            <KeywordDestinationEmptyState
                v-else-if="!selector.empty && selector.searchQuery.length"
            >
                <template #text> No negative lists match your search query. </template>
                <template #button>
                    <oButton color="white" @clicked="negativeListModalOpen = true">
                        Create Negative List
                    </oButton>
                </template>
            </KeywordDestinationEmptyState>
        </template>

        <template #bottom-row-negative-list="{ selector }">
            <oButton v-if="!selector.empty" color="white" @clicked="negativeListModalOpen = true">
                Create Negative List
            </oButton>
        </template>
    </KeywordDestinationSelector>

    <NewNegativeListModal
        v-if="existingSharedSetNames"
        v-model="negativeListModalOpen"
        :campaigns="newNegativeListCampaigns"
        :existing-shared-sets="existingSharedSetNames"
        @update:new-list-data="newList => handleNewListData(newList)"
    />

    <!-- Floating footer -->
    <teleport to="body">
        <div v-if="currentStepIndex === 1" class="fixed-footer-wrapper">
            <div class="fixed-footer-container">
                <Tooltip
                    :content="noDestinationSelected ? 'Please select at least one destination' : ''"
                    :offset="[0, 24]"
                >
                    <oButton
                        ref="addNegativesButton"
                        size="extra-large"
                        :disabled="noDestinationSelected"
                        :loading="addingNgramsToNegative"
                        @clicked="addNegatives"
                        >Add {{ selectionCount }} Negative
                        {{ selectionCount !== 1 ? 'Keywords' : 'Keyword'
                        }}<template #icon><RightArrowIcon hex="#fff" /></template>
                    </oButton>
                </Tooltip>
            </div>
        </div>
    </teleport>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'

import { oButton, RightArrowIcon, showAsyncPush, Tooltip } from '@opteo/components-next'

import { useNGramAddNegatives } from '@/composition/toolkit/nGramTool/useNGramAddNegatives'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import KeywordDestinationSelector from '@/components/improvement/shared-components/keyword-destination/KeywordDestinationSelector.vue'
import KeywordDestinationEntity from '@/components/improvement/shared-components/keyword-destination/KeywordDestinationEntity.vue'
import KeywordDestinationEmptyState from '@/components/improvement/shared-components/keyword-destination/KeywordDestinationEmptyState.vue'
import NewNegativeListModal from '@/components/improvement/shared-components/NewNegativeListModal.vue'
import { NgramTool } from '@opteo/types'
import {
    NegativeListDestination,
    ActiveEntityDestination,
    ActiveEntityDestinationChildren,
} from '@/composition/toolkit/nGramTool/types/index'
import { authRequest, Endpoint } from '@/composition/api/useAPI'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { useAccount } from '@/composition/account/useAccount'

const intercom = useIntercom()
const { negativeKeywords } = useNGramAddNegatives()
const {
    performanceMaxActive,
    destinations,
    accountLevelEntity,
    newNegativeListCampaigns,
    existingSharedSetNames,
    onPushError,
    mutateSharedSetData,
    sharedSetData,
    campaignData,
    currentStepIndex,
    resetState,
    mutateNGramData,
    addingNgramsToNegative,
    mutateChangeHistory,
} = useNGramTool()

const { accountPlatform } = useAccount()

defineProps<{
    selectionCount: number
}>()

const negativeListModalOpen = ref(false)

const entities = ref<{
    'negative-list': NegativeListDestination[]
    campaign: ActiveEntityDestination[]
}>({
    'negative-list': [],
    campaign: [],
})

async function handleNewListData(newSharedSetData: {
    shared_set_id: number
    shared_set_name: string
    shared_set_resource_name: string
}) {
    await mutateSharedSetData()

    generateEntities()

    const newlyAddedList = entities.value['negative-list'].find(
        list => +list.resourceName === newSharedSetData?.shared_set_id
    )

    if (newlyAddedList) {
        newlyAddedList.checked = true
    }
}

function generateEntities() {
    sharedSetData.value?.forEach(set => {
        const existingList = entities.value['negative-list'].find(
            list => +list.resourceName === set.shared_set_id
        )

        if (existingList) return

        entities.value['negative-list'].push({
            resourceName: set.shared_set_resource_name,
            label: set.shared_set_name,
            type: 'negative-list',
            checked: false,
        })
    })

    campaignData.value.forEach(campaign => {
        const existingCampaign = entities.value.campaign.find(
            existingCampaign => existingCampaign.id === campaign.campaign_id
        )

        if (existingCampaign) return

        entities.value.campaign.push({
            id: campaign.campaign_id,
            label: campaign.campaign_name,
            type: 'campaign',
            checked: false,
            children: campaign.adgroups_data.map(adGroup => {
                return {
                    id: adGroup.adgroup_id,
                    label: adGroup.adgroup,
                    type: 'adgroup',
                    checked: false,
                    source: false,
                }
            }),
        })
    })
}

onMounted(() => generateEntities())

const { push } = useRouter()

const addNegativesButton = ref()

const negativeKeywordsDestinations = computed(() => {
    const destinations: NgramTool.NegativeKeywordsDestinations = {
        accountLevel: accountLevelEntity.value.checked,
        adGroups: [],
        campaigns: [],
        sharedSets: [],
    }

    entities.value.campaign?.forEach((parentCampaign: ActiveEntityDestination) => {
        parentCampaign.children.forEach((adGroup: ActiveEntityDestinationChildren) => {
            if (!adGroup.checked) return

            destinations.adGroups.push({
                adGroupId: adGroup.id,
                adGroupName: adGroup.label,
                campaignName: parentCampaign.label,
            })
        })
    })

    entities.value.campaign?.forEach(campaign => {
        if (!campaign.checked) return

        destinations.campaigns.push({
            campaignId: campaign.id,
            campaignName: campaign.label,
        })
    })

    const negativeLists = entities.value['negative-list']?.filter(
        negativeList => negativeList.checked
    )

    negativeLists.forEach(negativeList => {
        if (!negativeList.checked) return

        destinations.sharedSets.push({
            sharedSetResourceName: negativeList.resourceName,
            sharedSetName: negativeList.label,
        })
    })

    return destinations
})

const noDestinationSelected = computed(() => {
    return (
        !negativeKeywordsDestinations.value.accountLevel &&
        !negativeKeywordsDestinations.value.adGroups.length &&
        !negativeKeywordsDestinations.value.campaigns.length &&
        !negativeKeywordsDestinations.value.sharedSets.length
    )
})

// Adding the Negatives
async function addNegatives() {
    if (noDestinationSelected.value) {
        addNegativesButton.value?.flashError()
        return
    }

    addingNgramsToNegative.value = true

    const toast = showAsyncPush({
        hideAfterSuccessMs: 6000,
        platform: accountPlatform.value,
    })

    try {
        await authRequest(Endpoint.AddNegativeNgrams, {
            body: {
                negativeKeywords: negativeKeywords.value,
                negativeKeywordsDestinations: negativeKeywordsDestinations.value,
            },
        })

        addingNgramsToNegative.value = false
        addNegativesButton.value?.flashSuccess()

        resetState()
        push({ name: Routes.ToolkitNGramTool })
        toast.showSuccessState()
        mutateNGramData()
        mutateChangeHistory()

        intercom.trackEvent('ngram_negative_pushed')
    } catch (err) {
        console.log(err)
        addingNgramsToNegative.value = false
        toast.showErrorState()
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.spinner-container {
    height: 1.25rem;
    width: 1.25rem;
    @include flex-center;
}

.ngram-cell,
:deep(.ngram-cell) {
    display: flex;
    align-items: center;
    padding-left: 1.875rem;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
}

:deep(.nscore-popout-wrapper) {
    display: flex;
    align-items: center;
}

.select-ngram-buttons {
    display: grid;
    grid-template-columns: 240px auto 146px;
    gap: 0.75rem;
}

.nscore-cell,
.conflicts-cell,
.keyword-source-cell,
:deep(.nscore-cell, .conflicts-cell, .keyword-source-cell) {
    display: flex;
    align-items: center;
}
.conflicts-cell {
    position: relative;
    cursor: pointer;
}

.info-circle-icon,
:deep(.info-circle-icon) {
    width: 1rem;
    cursor: pointer;
}

.fixed-footer-wrapper {
    @include flex-center;
    gap: 2rem;
    margin: auto;
    @include fixed;
    @include bg-opteo-white;
    @include z-10;
    @include container;
    border-radius: 0;
    @include pv-32;
    width: 100vw;
    bottom: 0;
    left: 0;
}
.fixed-footer-container {
    @include br-16;
    @include ph-24;
}

.empty-state-container {
    @include container;
    @include flex-center;
    padding: 7rem 2rem;
    flex-direction: column;
    gap: 1rem;
}

.shift-away-enter-active,
.shift-away-leave-active {
    transition-property: transform, visibility, opacity;
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
    transition-duration: 0.3s;
}

.shift-away-enter-from,
.shift-away-leave-to {
    opacity: 0;
    transform: translate(-50%, 0rem);
}
</style>
