<template>
    <Panel title="Keywords" v-model="showPanel" @close="closePanel" :width="1178">
        <template #title>
            <EntityPill :type="singleItemPanel?.entityType" :content="singleItemPanel?.text" />
        </template>

        <template #content>
            <oTable
                :headers="panelItemHeaders"
                :items="[singleItemPanel] as unknown as Record<string, number | string>[]"
                fixed-layout
                :border-radius="24"
            >
                <template #column.text="{ value, row }: { value: string; row: PanelItem }">
                    <div class="ngram-cell">
                        <EntityPill :type="row.entityType" :content="value" /><Spacer
                            width="0.5rem"
                        />
                        <template v-if="isNGramItem(row)">
                            <!-- @vue-expect-error: wrongly assumed to be able to v-bind as not exactly the same types -->
                            <NScoreCell v-bind="row" />
                        </template>
                    </div>
                </template>
                <template #column.cost="{ value }">
                    <Money :value="value" :currency="currencyCode" />
                </template>
                <template #column.impressions="{ value }">
                    <Number :value="value" />
                </template>
                <template #column.conversions="{ value }">
                    <Number :value="value" />
                </template>
                <template #column.conversionValue="{ value }">
                    <Money :value="value" :currency="currencyCode" />
                </template>
                <template #column.cpa="{ value }">
                    <Money :value="value" :currency="currencyCode" />
                </template>
                <template #column.roas="{ value }">
                    <Percent :value="value" />
                </template>
                <template #column.cpm="{ value }">
                    {{ formatCpm(value) }}
                </template>
                <template #column.vpm="{ value }">
                    {{ formatVpm(value) }}
                </template>
                <template #column.vsAvg="{ value, row }">
                    <Text size="f-8" weight="500" :color="row.vsAverageColor">
                        <Percent :value="value" :decimal-places="2" include-sign />
                    </Text>
                </template>
                <!-- Only relevant when adding N-Grams -->
                <template #column.potentialSavings="{ value }">
                    <Pill><Money :value="value" :currency="currencyCode" />/month</Pill>
                </template>
                <!-- Only relevant when adding Search Terms -->
                <template #[`column.${entitySource}`]="{ row }">
                    <NGramEntitySource :search-term-item="row" />
                </template>
            </oTable>
            <Spacer height="2.375rem" />
            <BlockNote>
                Adding this n-gram as a negative would affect the following keywords
            </BlockNote>
            <Spacer height="2.375rem" />
            <oTable
                v-if="(keywordsReady && keywords?.length) || !keywordsReady"
                :headers="keywordHeaders"
                :items="keywords"
                fixed-layout
                :border-radius="24"
                order-by="cost"
                order="DESC"
                paginationMethod="intersection"
                class="blocked-keywords-table"
            >
                <template #column.keywordText="{ value }">
                    <Tooltip
                        v-if="keywordsReady"
                        :visibleOnlyWhenOverflow="true"
                        :content="value"
                        :offset="[-8, 20]"
                        :delay="[400, 0]"
                    >
                        <EntityPill :type="Improvement.LocationEntity.Keyword" :content="value" />
                    </Tooltip>

                    <EntityPill v-else :type="Improvement.LocationEntity.Keyword">
                        <Skeleton
                            width="6rem"
                            color="hsla(174, 100%, 22%, 0.2)"
                            shimmer-color="hsla(174, 100%, 22%, 0.06)"
                        />
                    </EntityPill>
                </template>
                <template #column.campaignName="{ value }">
                    <Tooltip
                        v-if="keywordsReady"
                        :visibleOnlyWhenOverflow="true"
                        :content="value"
                        :offset="[-8, 20]"
                        :delay="[200, 0]"
                    >
                        <EntityPill :type="Improvement.LocationEntity.Campaign" :content="value" />
                    </Tooltip>

                    <EntityPill v-else :type="Improvement.LocationEntity.Campaign">
                        <Skeleton
                            width="6rem"
                            color="hsla(214, 100%, 50%, 0.2)"
                            shimmer-color="hsla(214, 100%, 50%, 0.06)"
                        />
                    </EntityPill>
                </template>
                <template #column.adGroupName="{ value }">
                    <Tooltip
                        v-if="keywordsReady"
                        :visibleOnlyWhenOverflow="true"
                        :content="value"
                        :offset="[-8, 20]"
                        :delay="[200, 0]"
                    >
                        <EntityPill :type="Improvement.LocationEntity.AdGroup" :content="value" />
                    </Tooltip>

                    <EntityPill v-else :type="Improvement.LocationEntity.AdGroup">
                        <Skeleton
                            width="6rem"
                            color="hsla(256, 100%, 61%, 0.2)"
                            shimmer-color="hsla(256, 100%, 61%, 0.06)"
                        />
                    </EntityPill>
                </template>

                <template #column.cost="{ value }">
                    <Money v-if="keywordsReady" :value="value" :currency="currencyCode" />

                    <Skeleton v-else width="3rem" />
                </template>
                <template #column.impressions="{ value }">
                    <Number v-if="keywordsReady" :value="value" />
                    <Skeleton v-else width="3rem" />
                </template>
                <template #column.clicks="{ value }">
                    <Number v-if="keywordsReady" :value="value" />
                    <Skeleton v-else width="3rem" />
                </template>
                <template #column.conversions="{ value }">
                    <Number v-if="keywordsReady" :value="value" />
                    <Skeleton v-else width="3rem" />
                </template>
                <template #column.conversionValue="{ value }">
                    <Money v-if="keywordsReady" :value="value" :currency="currencyCode" />
                    <Skeleton v-else width="3rem" />
                </template>
                <template #column.cpa="{ value }">
                    <Money v-if="keywordsReady" :value="value" :currency="currencyCode" />
                    <Skeleton v-else width="3rem" />
                </template>
                <template #column.roas="{ value }">
                    <Percent v-if="keywordsReady" :value="value" />
                    <Skeleton v-else width="3rem" />
                </template>
                <template #column.cpm="{ value }">
                    <span v-if="keywordsReady"> {{ formatCpm(value) }}</span>
                    <Skeleton v-else width="3rem" />
                </template>
                <template #column.vpm="{ value }">
                    <span v-if="keywordsReady">
                        {{ formatVpm(value) }}
                    </span>
                    <Skeleton v-else width="3rem" />
                </template>
                <template #column.vsAvg="{ value, row }">
                    <Text v-if="keywordsReady" size="f-8" weight="500" :color="row.vsAverageColor">
                        <Text
                            size="f-8"
                            color="gray"
                            weight="500"
                            v-if="
                                (row.cost === 0 && row.conversions === 0) ||
                                (row.cost === 0 && row.conversionValue === 0)
                            "
                        >
                            N/A
                        </Text>
                        <Percent v-else :value="value" :decimal-places="2" include-sign />
                    </Text>
                    <Skeleton v-else width="3rem" />
                </template>
                <template #column.matchType="{ value }">
                    <Text size="f-8" v-if="keywordsReady"> {{ enumToStringMap[value] }} </Text>
                    <Skeleton v-else width="3rem" />
                </template>
            </oTable>
            <!-- Empty State Todo(design): create empty state -->
            <div v-else class="keywords-panel-empty-state-container">
                <Text size="f-8" align="center"> No blocking keywords found </Text>
            </div>
        </template>
    </Panel>
</template>

<script lang="ts" setup>
import { useAccount } from '@/composition/account/useAccount'
import { Endpoint, useAPI } from '@/composition/api/useAPI'
import { useNGramFilters } from '@/composition/toolkit/nGramTool/useNGramFilters'
import { useNGramHeaders } from '@/composition/toolkit/nGramTool/useNGramHeaders'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import { useNgramPanels } from '@/composition/toolkit/nGramTool/useNgramPanels'
import {
    Panel,
    EntityPill,
    Text,
    Spacer,
    oTable,
    Tooltip,
    Percent,
    Number,
    Money,
} from '@opteo/components-next'
import BlockNote from '@/components/toolkit/ngram/BlockNote.vue'
import NGramEntitySource from './NGramEntitySource.vue'
import Skeleton from '@/components/util/Skeleton.vue'
import Pill from '@/components/toolkit/ngram/Pill.vue'
import NScoreCell from './NScoreCell.vue'
import { Gads, Improvement, NgramTool } from '@opteo/types'
import { computed } from 'vue'
import { isNGramItem } from '@/composition/toolkit/nGramTool/utils'

import type { PanelItem } from '@/composition/toolkit/nGramTool/types/index'

const enumToStringMap: Record<any, any> = {
    [Gads.enums.KeywordMatchType.EXACT]: 'Exact',
    [Gads.enums.KeywordMatchType.PHRASE]: 'Phrase',
    [Gads.enums.KeywordMatchType.BROAD]: 'Broad',
}

const { entitySource, panelItemHeaders, relevantModeHeaders } = useNGramHeaders()
const { singleItemPanel, activePanel, closePanel } = useNgramPanels()

const { accountId } = useAccount()
const { lookbackWindow } = useNGramFilters()
const {
    calculatePerformanceMetric,
    ngramAverage,
    formatCpm,
    formatVpm,
    generateVsAvgColor,
    currencyCode,
} = useNGramTool()

const showPanel = computed(() => !!(activePanel.value === 'keywords' && singleItemPanel.value))

const { data: blockedKeywords, isValidating: blockedKeywordsLoading } = useAPI<
    {
        resourceName: string
        matchType: Gads.enums.KeywordMatchType
        adGroupId: number
        keywordId: number
    }[]
>(Endpoint.GetNgramAffectedKeywords, {
    body: () => ({
        accountId: accountId.value,
        ngram: singleItemPanel.value?.text,
    }),
    uniqueId: () => `${accountId.value}:${singleItemPanel.value?.text}`,
    waitFor: () => showPanel.value && singleItemPanel.value,
})

const keywordResourceNames = computed(() =>
    singleItemPanel.value ? blockedKeywords.value?.map(keyword => keyword.resourceName) : []
)

const { data: keywordMetrics, isValidating: keywordMetricsLoading } = useAPI<
    NgramTool.KeywordMetrics[]
>(Endpoint.GetKeywordMetrics, {
    body: () => ({
        accountId: accountId.value,
        keywordResourceNames: keywordResourceNames.value,
        lookbackWindow: lookbackWindow.value.value,
    }),
    uniqueId: () => `${JSON.stringify(keywordResourceNames.value)}-${singleItemPanel.value?.text}`,
    waitFor: () =>
        !!(showPanel.value && keywordResourceNames.value && keywordResourceNames.value.length > 0),
})

const keywordsReady = computed(() => !blockedKeywordsLoading.value && !keywordMetricsLoading.value)

const keywords = computed(() => {
    const skeletons = new Array(15).fill({})
    if (!keywordsReady.value && (!blockedKeywords.value || !keywordMetrics.value)) return skeletons

    return blockedKeywords.value?.map(affectedKeyword => {
        const metrics = keywordMetrics.value?.find(
            keyword =>
                `${keyword.adGroupId}~${keyword.keywordId}` ===
                `${affectedKeyword.adGroupId}~${affectedKeyword.keywordId}`
        )

        if (!metrics) return affectedKeyword

        const performanceMetric = calculatePerformanceMetric(
            metrics.impressions,
            metrics.cost,
            metrics.conversions,
            metrics.conversionValue
        )

        const vsAvg = (performanceMetric - ngramAverage.value) / ngramAverage.value

        const vsAverageColor = generateVsAvgColor(vsAvg)

        return {
            cpa: performanceMetric,
            roas: performanceMetric,
            cpm: performanceMetric,
            vpm: performanceMetric,
            vsAvg,
            vsAverageColor,
            ...metrics,
            ...affectedKeyword,
        }
    })
})

const keywordHeaders = computed(() => [
    { key: 'keywordText', text: 'Keyword', vPadding: '0.875rem', sortable: true },
    { key: 'campaignName', text: 'Campaign', vPadding: '0.875rem', sortable: true },
    { key: 'adGroupName', text: 'Ad Group', vPadding: '0.875rem', sortable: true },
    { key: 'matchType', text: 'Type', width: 96, vPadding: '0.875rem', sortable: true },
    // { key: 'impressions', text: 'Impr.', width: 96, vPadding: '0.875rem', sortable: true },
    // { key: 'clicks', text: 'Clicks', width: 96, vPadding: '0.875rem', sortable: true },
    ...relevantModeHeaders.value.map(header => ({
        ...header,
    })),
    {
        key: 'vsAvg',
        text: 'vs Avg.',
        width: 128,
        vPadding: '0.875rem',
        sortable: true,
    },
])
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.ngram-cell {
    display: flex;
    align-items: center;
}

.blocked-keywords-table td[data-label='Keyword'],
.blocked-keywords-table td[data-label='Campaign'],
.blocked-keywords-table td[data-label='Ad Group'] {
    overflow: hidden;
    position: relative;
}
.blocked-keywords-table td[data-label='Keyword']::after,
.blocked-keywords-table td[data-label='Campaign']::after,
.blocked-keywords-table td[data-label='Ad Group']::after {
    content: '';
    background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: -1rem;
    width: 6rem;
    bottom: 0;
    pointer-events: none;
}

.keywords-panel-empty-state-container {
    @include flex-center;
    flex-direction: column;
    max-width: 23.25rem;
}
</style>
