<template>
    <Modal
        id="create-negative-list-modal"
        :model-value="modelValue"
        @update:modelValue="$emit('update:modelValue', $event)"
        title="Create Negative List"
        width="760px"
        contentMaxHeight="578px"
    >
        <template #content>
            <div class="create-negative-list-modal-form">
                <oInput
                    ref="negativeListNameInput"
                    label="Negative List Name"
                    type="text"
                    v-model="negativeListName"
                />
                <Spacer />
                <Label>Add to Campaigns</Label>
                <div class="negative-list-campaigns">
                    <div class="search-input-container">
                        <oInput class="search-input" v-model="searchQuery" />
                    </div>
                    <div
                        role="button"
                        v-if="filteredCampaigns.length"
                        v-for="(campaign, index) in filteredCampaigns"
                        class="negative-list-campaign-item"
                        @click="filteredCampaigns[index].checked = !campaign.checked"
                    >
                        <Checkbox :model-value="campaign.checked" />
                        <Spacer width="1rem" />
                        <EntityPill
                            :type="Improvement.LocationEntity.Campaign"
                            :content="campaign.label"
                        />
                    </div>
                    <div
                        v-else-if="!filteredCampaigns.length && searchQuery.length"
                        class="empty-state"
                    >
                        <Text size="f-8">No campaigns match the search query</Text>
                    </div>
                    <div v-else class="empty-state">
                        <Text size="f-8">No campaigns exist in this account</Text>
                    </div>
                </div>
            </div>
        </template>
        <template #buttons>
            <oButton color="white" @clicked="$emit('update:modelValue', false)"> Cancel </oButton>
            <Spacer width="0.75rem" />
            <oButton color="blue" @clicked="createNegativeList" :loading="loading">
                Create Negative List
            </oButton>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import {
    Modal,
    oInput,
    Label,
    Checkbox,
    Spacer,
    EntityPill,
    oButton,
    Text,
} from '@opteo/components-next'
import { enums } from '@opteo/types/gads'
import { AdWords } from '@opteo/types'
import { Improvement } from '@opteo/types'

import { Endpoint, authRequest } from '@/composition/api/useAPI'
import { useAccount } from '@/composition/account/useAccount'

type Props = {
    modelValue: boolean
    campaigns: { value: string; label: string }[]
    existingSharedSets: string[]
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
})
const { accountId, domainId } = useAccount()

const emit = defineEmits<{
    (e: 'update:modelValue', open: boolean): void
    (e: 'update:newListData', sharedSetdata: AdWords.SharedNegativeList): void
}>()

const negativeListNameInput = ref()
const negativeListName = ref('')

const localCampaigns = ref(props.campaigns.map(campaign => ({ ...campaign, checked: false })))
const searchQuery = ref('')

const filteredCampaigns = computed(() =>
    localCampaigns.value.filter(campaign =>
        campaign.label.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
)

const loading = ref(false)

async function createNegativeList() {
    const campaign_ids = localCampaigns.value
        .filter(campaign => campaign.checked)
        .map(checked => checked.value)

    loading.value = true

    const sharedSetExistsByName = props?.existingSharedSets?.find(
        existingSet => existingSet === negativeListName.value
    )
    if (sharedSetExistsByName) {
        negativeListNameInput.value?.setError(
            `A negative list named "${negativeListName.value}" already exists`
        )
        loading.value = false

        throw new Error('Shared set already exists.')
    }

    const newSharedSetRn = await authRequest(Endpoint.CreateCustomSharedSetWithCampaigns, {
        body: {
            name: negativeListName.value,
            campaign_ids: campaign_ids,
            account_id: accountId.value,
            domain_id: domainId.value,
        },
    }).catch(e => {
        loading.value = false
        const err = e?.message ?? 'Unknown error occured'
        throw new Error(err)
    })

    const newSharedSetData = {
        shared_set_id: +newSharedSetRn.split('sharedSets/')[1],
        shared_set_name: negativeListName.value,
        shared_set_resource_name: newSharedSetRn,
    }
    emit('update:newListData', newSharedSetData)
    emit('update:modelValue', false)

    loading.value = false
}

watch(
    () => props.modelValue,
    open => {
        if (!open) {
            negativeListName.value = ''
            localCampaigns.value = props.campaigns.map(campaign => ({
                ...campaign,
                checked: false,
            }))
            searchQuery.value = ''
        }
    }
)
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.create-negative-list-modal-form {
    max-width: 400px;
    padding: 1rem 0rem;
    margin: 0 auto;
}
.negative-list-campaigns {
    @include container;
    border-radius: 1.25rem;
    overflow: hidden;
}
.negative-list-campaign-item {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    overflow: hidden;
    position: relative;
    width: 100%;
    cursor: pointer;
}
.negative-list-campaign-item:after {
    content: '';
    background: #fff;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 80%);
    width: 6rem;
    right: 0;
    height: calc(100% - 8px);
    top: 4px;
    position: absolute;
    pointer-events: none;
}
.negative-list-campaign-item:not(:last-child) {
    border-bottom: 1px solid;
    @include opteo-border;
}

.empty-state {
    // @include container;
    @include flex-center;
    @include ph-24;
    @include pv-44;
}

// search input
.search-input-container {
    padding: 1.5rem;
    border-bottom: 1px solid;
    @include opteo-border;
}
.search-input {
    height: 100%;
    @include relative;
}
:deep(.search-input label),
:deep(.search-input .o-input__wrapper) {
    display: block;
    height: 100%;
}

:deep(.search-input input) {
    padding: 14px 14px 14px 38px;
    height: 100%;
}
.search-input::before {
    content: '';
    position: absolute;
    left: 14px;
    top: 0;
    bottom: 0;
    width: 16px;
    background: url('@/assets/img/icons/search-icon.svg') center / contain no-repeat;
}
</style>
